import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import PurposeList from '../components/pages/PurposesList';
import PosterPurposeDownload from '../components/pages/PosterPurposeDownload';
import SEO from '../components/seo';
import '../styles/app.scss';

const PlayWithPurpose = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <PurposeList purposeList={post.acf} />
            <PosterPurposeDownload posterData={post.acf} />
            <Footer />
        </>
    );
};

export default PlayWithPurpose;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                play_with_a_purpose_description
                purpose_list {
                    purpose_text
                    purpose_name
                    purpose_start_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    srcSetWebp
                                    srcSet
                                    src
                                }
                            }
                        }
                    }
                    purpose_kid_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcSetWebp
                                    srcWebp
                                    srcSet
                                    src
                                }
                            }
                        }
                    }
                }
                purpose_poster_description
                purpose_poster_title
                purpose_poster_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcSetWebp
                                srcWebp
                                srcSet
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
